import React from "react";

export default function TextArea(props) {
  return (
    <div className="mb-6 font-abel">
      <label className="block mb-2 text-md font-normal text-gray-900 dark:text-gray-300">
        {props.name}
      </label>
      <textarea
        rows={props.rows}
        className="bg-gray-50 border border-gray-300 placeholder:font-normal text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder={props.placeholder}
        required
        name={props.name}
        onChange={props.onChange}
      ></textarea>
    </div>
  );
}
