import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../UI/Button";
import Input from "../UI/Input";
import TextArea from "../UI/TextArea";

export default function UpdateEvent() {
  let navigate = useNavigate();
  const params = useParams();

  const [eventname, setEventName] = useState("");
  const [link, setLink] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [keyword, setKeyword] = useState("");
  const [ticketlink, setTicketlink] = useState("");
  const [venue, setVenue] = useState("");
  const [info, setInfo] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [errMsg, setErrMsg] = useState("");

  var eventUpdateUrl = `${process.env.REACT_APP_BASEURL_API}/update-event/${params.id}`;

  // const d = new Date();
  // let day = d.getDay();
  // console.log(day);
  // if (day === 0) {
  //   console.log("Sunday");
  // } else if (day === 5) {
  //   console.log("Fri");
  // } else {
  //   console.log("Saturday");
  // }

  const eventUpdator = async () => {
    const formdata = new FormData();

    formdata.append("eventname", eventname);
    formdata.append("link", link);
    formdata.append("startDateTime", startDateTime);
    formdata.append("endDateTime", endDateTime);
    formdata.append("price", price);
    formdata.append("currency", currency);
    formdata.append("keyword", keyword);
    formdata.append("ticketlink", ticketlink);
    formdata.append("venue", venue);
    formdata.append("info", info);
    formdata.append("imageUrl", imageUrl);

    if (
      eventname === "" ||
      price === "" ||
      link === "" ||
      startDateTime === "" ||
      endDateTime === "" ||
      currency === "" ||
      keyword === "" ||
      ticketlink === "" ||
      venue === "" ||
      info === "" ||
      imageUrl === ""
    ) {
      setErrMsg("Missing input field");
    } else {
      setErrMsg("");

      axios
        .put(eventUpdateUrl, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
          // Always write this in order not to have CORS issues
          withCredentials: false,
          crossdomain: false,
          mode: "no-cors",
        })
        .then((res) => {
          setEventName("");
          setLink("");
          setStartDateTime("");
          setEndDateTime("");
          setPrice("");
          setCurrency("");
          setKeyword("");
          setTicketlink("");
          setVenue("");
          setInfo("");
          setImageUrl("");

          toast(`Event Updated Successfully `, {
            type: "success",
          });
          setTimeout(() => {
            navigate(`/events`);
            // console.log(params.id);
          }, 6000);
        });
    }
  };

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let user = getCookie("userId");
    if (user !== "") {
      // console.log("User present");
    } else {
      // window.location="/login"
      navigate("/");
      // if (user !== "" && user != null) {
      //   setCookie("userId", user, 1);
      // }
    }
  }

  useEffect(() => {
    checkCookie();
  }, []);

  return (
    <>
      <ToastContainer />
      <Link
        className="float-left text-start text-mhash w-fit h-fit hover:text-mhred text-xl  font-montserrat "
        to={"/events"}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-10 w-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
      </Link>

      <div className="text-3xl text-gray-800 text-center font-aldrich pb-16">
        Update Event
      </div>

      <div
        className={` ${
          errMsg === ""
            ? "hidden"
            : "block text-center duration-75 font-abel text-md text-mhred"
        }`}
      >
        {errMsg}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 lg:px-12 md:px-4">
        <Input
          name="Event"
          placeholder="Event Name"
          type="text"
          onChange={(e) => {
            setEventName(e.target.value);
          }}
        />
        <Input
          name="Event Link"
          placeholder="https://motherhonestly.com"
          type="text"
          onChange={(e) => {
            setLink(e.target.value);
          }}
        />
        <Input
          name="Start Time"
          placeholder="8:00 AM"
          type="datetime-local"
          onChange={(e) => {
            setStartDateTime(e.target.value);
          }}
        />
        <Input
          name="End Time"
          placeholder="10:00 PM"
          type="datetime-local"
          onChange={(e) => {
            setEndDateTime(e.target.value);
          }}
        />

        <Input
          name="Ticket Price"
          placeholder="$"
          type="number"
          onChange={(e) => {
            setPrice(e.target.value);
          }}
        />

        <div>
          <label className="block mb-2 text-md font-normal  text-gray-900 dark:text-gray-300">
            Currency
          </label>
          <select
            className="bg-gray-50 border border-gray-300 placeholder:font-normal text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            aria-label="Default select example"
            required
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
          >
            <option>--Select--</option>
            <option value="$">$</option>
            <option value="&#x20A6;">&#x20A6;</option>
          </select>
        </div>

        <Input
          name="Ticket KeyWord"
          placeholder="Purchase"
          type="text"
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
        />
        <Input
          name="Ticket Link"
          placeholder="Purchase Link"
          type="text"
          onChange={(e) => {
            setTicketlink(e.target.value);
          }}
        />
        <TextArea
          name="Venue"
          rows={3}
          placeholder="Venue of Event"
          onChange={(e) => {
            setVenue(e.target.value);
          }}
        />
        <TextArea
          name="Description"
          rows={3}
          placeholder="Event Information"
          onChange={(e) => {
            setInfo(e.target.value);
          }}
        />
        <Input
          name="Image Url"
          placeholder="https://motherhonestly.com"
          type="text"
          onChange={(e) => {
            setImageUrl(e.target.value);
          }}
        />

        <Button
          divName="mb-6 mt-10"
          onClick={() => eventUpdator()}
          name="Update Event"
          className="bg-yellow-400 hover:bg-yellow-300 float-right text-black w-full py-[13px] font-abel rounded-md"
        />
      </div>
    </>
  );
}
