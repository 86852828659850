import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BigLoading from "../UI/BigLoading";
import UpdateToolkit from "./UpdateToolkit";

export default function ViewToolkit() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  let navigate = useNavigate();
  const [toolkit, setToolkit] = useState([]);

  const params = useParams();

  var viewUrl = `${process.env.REACT_APP_BASEURL_API}/admin-view/${params.id}`;

  var vUrl = `${process.env.REACT_APP_BASEURL_API}/admin-view`;

  var downUrl = `${process.env.REACT_APP_BASEURL_API}/toolkits`;

  const getToolkit = async () => {
    try {
      const response = await fetch(viewUrl, {
        method: "GET",
      });
      const jsonData = await response.json();
      setToolkit(jsonData);
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let user = getCookie("userId");
    if (user !== "") {
      // console.log("User present");
    } else {
      // window.location="/login"
      navigate("/");
      // if (user !== "" && user !== null) {
      //   setCookie("userId", user, 1);
      // }
    }
  }

  useEffect(() => {
    getToolkit();
    checkCookie();
  }, []);

  const deleteToolKit = async (id) => {
    try {
      const deleteToolkit = await fetch(`${vUrl}/${id}`, {
        method: "DELETE",
      });
      //  window.location = "/";
      navigate("/access");
    } catch (err) {
      console.error(err.message);
    }
  };
  return (
    <Fragment>
      <div className={`${toolkit.length === 0 ? "hidden" : "block"}`}>
        <Link
          className="float-left text-start text-mhash w-fit h-fit hover:text-mhred text-xl  font-montserrat "
          to={"/access"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
            />
          </svg>
        </Link>

        <div className="flex justify-center mb-12">
          <ul className=" flex flex-wrap justify-center bg-gray-500 uppercase w-auto overflow-x-hidden md:w-auto lg:w-fit h-16 md:h-14 py-2 px-6 text-xs md:text-sm md:font-medium rounded-full text-center text-gray-500 dark:text-gray-400">
            <li className="-ml-2 mr-auto cursor-pointer md:-ml-2 text-xs md:text-sm">
              <button
                aria-current="page"
                className={`${
                  toggleState === 1 ? "bg-white " : "bg-tranparent"
                } inline-block w-24 md:w-40 lg:w-32 md:py-2 py-2 px-3 text-gray-500 rounded-full bgactive`}
                onClick={() => toggleTab(1)}
              >
                <p
                  className={`${
                    toggleState === 2 ? "text-white " : "text-black"
                  } font-semibold block md:block `}
                >
                  View Toolkit
                </p>
              </button>
            </li>

            <li className="-ml-2 mr-auto cursor-pointer md:-ml-2 text-xs md:text-sm">
              <button
                aria-current="page"
                className={`${
                  toggleState === 2 ? "bg-white " : "bg-tranparent"
                } inline-block w-20 md:w-40 lg:w-32 md:py-2 py-2 px-3 text-gray-500 rounded-full bgactive`}
                onClick={() => toggleTab(2)}
              >
                <p
                  className={`${
                    toggleState === 1 ? "text-white " : "text-black"
                  } font-semibold block md:block `}
                >
                  Edit Toolkit
                </p>
              </button>
            </li>
          </ul>
        </div>

        <div className="place-content-center">
          <div
            className={`${
              toggleState === 1
                ? "md:grid md:grid-cols-2 grid-cols-1 gap-4"
                : "hidden"
            }`}
          >
            <div className="col-span-1">
              <img
                className="w-fit mb-4 bg-white p-2 drop-shadow-xl"
                src={`${downUrl}/${toolkit.toolkit_img}`}
                alt="Toolkit_Image"
              />
            </div>
            <div className="col-span-1">
              <p className="text-md lg:text-3xl font-abel text-black">
                {toolkit.title}
              </p>
              <p className="text-md font-abel text-black">
                Price: {toolkit.price}
              </p>
              <p className="text-md font-abel text-black">
                Category: {toolkit.categ}
              </p>
              <button
                type="button"
                className="bg-gray-600 hover:bg-gray-500 text-base text-white w-fit py-2 px-2 font-abel rounded-md"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <i className="fas fa-trash text-white mr-1"></i>
                DELETE
              </button>
              <p className="text-md mt-4 font-abel text-gray-800 w-fit text-justify">
                {toolkit.description}
              </p>

              <button
                onClick={() => {
                  window.open(toolkit.toolkit_file);
                }}
                className="text-md mt-2 text-start font-abel text-blue-700 cursor-pointer"
              >
                {toolkit.toolkit_file}
              </button>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title font-[400]" id="exampleModalLabel">
                  Confirm Delete
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body font-[400]">
                <h5>Are you sure you want to delete this toolkit? </h5>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="text-base font-abel font-[200] py-1 px-3 rounded-sm ring-1 ring-mhyellow text-mhyellow hover:text-mhyellow hover:ring-yellow-500"
                  data-bs-dismiss="modal"
                >
                  Back
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="text-base font-abel font-[200] py-1 px-3 rounded-sm ring-1 ring-mhred hover:text-mhred text-mhred hover:ring-red-700"
                  onClick={() => deleteToolKit(toolkit.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {toggleState === 2 ? <UpdateToolkit /> : null}
      </div>

      <div className={`${toolkit.length === 0 ? "block mt-32" : "hidden"}`}>
        <BigLoading />
      </div>
    </Fragment>
  );
}
