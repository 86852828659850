import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement);
const LineChart = () => {
  const [chartData, setChartData] = useState([]);

  var toolkitUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-view`;
  useEffect(() => {
    return () => {
      axios
        .get(toolkitUrl)
        .then((res) => {
          const jsonData = res.data;
          setChartData(jsonData);
          // console.log(jsonData);
        })
        .catch((error) => {
          console.log(error);
        });
    };
  }, []);
  var data = {
    labels: chartData.map((x) => x.title), // ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "Total Downloads Per Month",
        data: chartData.map((x) => x.id), // [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legends: {
      labels: {
        fontSize: 26,
      },
    },
  };

  return (
    <div>
      <Line data={data} height={400} />
    </div>
  );
};

export default LineChart;
