import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../UI/Button";
import Input from "../UI/Input";
import TextArea from "../UI/TextArea";

export default function CreateEvent() {
  let navigate = useNavigate();
  const [eventname, setEventName] = useState("");
  const [link, setLink] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [keyword, setKeyword] = useState("");
  const [ticketlink, setTicketlink] = useState("");
  const [venue, setVenue] = useState("");
  const [info, setInfo] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  var eventCreateUrl = `${process.env.REACT_APP_BASEURL_API}/create-event`;

  const eventCreator = async () => {
    const formdata = new FormData();

    formdata.append("eventname", eventname);
    formdata.append("link", link);
    formdata.append("startDateTime", startDateTime);
    formdata.append("endDateTime", endDateTime);
    formdata.append("price", price);
    formdata.append("currency", currency);
    formdata.append("keyword", keyword);
    formdata.append("ticketlink", ticketlink);
    formdata.append("venue", venue);
    formdata.append("info", info);
    formdata.append("imageUrl", imageUrl);

    await axios
      .post(eventCreateUrl, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
        },
        withCredentials: false,
        crossdomain: false,
        mode: "no-cors",
      })
      .then((res) => {
        setEventName("");
        setLink("");
        setStartDateTime("");
        setEndDateTime("");
        setPrice("");
        setCurrency("");
        setKeyword("");
        setTicketlink("");
        setVenue("");
        setInfo("");
        setImageUrl("");

        toast(`Event Created Successfully `, {
          type: "success",
        });
        setTimeout(() => {
          navigate(`/events`);
        }, 6000);
      });
  };

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let user = getCookie("userId");
    if (user !== "") {
      // console.log("User present");
    } else {
      // window.location="/login"
      navigate("/");
      // if (user !== "" && user != null) {
      //   setCookie("userId", user, 1);
      // }
    }
  }

  useEffect(() => {
    checkCookie();
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="text-3xl text-gray-800 text-center font-aldrich pb-16">
        Create Event
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 lg:px-12 md:px-4">
        <Input
          name="Event"
          placeholder="Event Name"
          type="text"
          onChange={(e) => {
            setEventName(e.target.value);
          }}
        />
        <Input
          name="Event Link"
          placeholder="https://motherhonestly.com"
          type="text"
          onChange={(e) => {
            setLink(e.target.value);
          }}
        />
        <Input
          name="Start Time"
          placeholder="8:00 AM"
          type="datetime-local"
          onChange={(e) => {
            setStartDateTime(e.target.value);
          }}
        />
        <Input
          name="End Time"
          placeholder="10:00 PM"
          type="datetime-local"
          onChange={(e) => {
            setEndDateTime(e.target.value);
          }}
        />

        <Input
          name="Ticket Price"
          placeholder="$"
          type="number"
          onChange={(e) => {
            setPrice(e.target.value);
          }}
        />

        <div>
          <label className="block mb-2 text-md font-normal  text-gray-900 dark:text-gray-300">
            Currency
          </label>
          <select
            className="bg-gray-50 border border-gray-300 placeholder:font-normal text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            aria-label="Default select example"
            required
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
          >
            <option>--Select--</option>
            <option value="$">$</option>
            <option value="&#x20A6;">&#x20A6;</option>
          </select>
        </div>

        <Input
          name="Ticket KeyWord"
          placeholder="Purchase"
          type="text"
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
        />
        <Input
          name="Ticket Link"
          placeholder="Purchase Link"
          type="text"
          onChange={(e) => {
            setTicketlink(e.target.value);
          }}
        />
        <TextArea
          name="Venue"
          rows={3}
          placeholder="Venue of Event"
          onChange={(e) => {
            setVenue(e.target.value);
          }}
        />
        <TextArea
          name="Description"
          rows={3}
          placeholder="Event Information"
          onChange={(e) => {
            setInfo(e.target.value);
          }}
        />
        <Input
          name="Image Url"
          placeholder="https://motherhonestly.com"
          type="text"
          onChange={(e) => {
            setImageUrl(e.target.value);
          }}
        />

        <Button
          divName="mb-6 mt-10"
          onClick={() => eventCreator()}
          name="Create Event"
          className="bg-green-600 hover:bg-green-500 float-right text-white w-full py-[13px] font-abel rounded-md"
        />
      </div>
    </>
  );
}
