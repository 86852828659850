import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import GridLazy from "../UI/GridLazy";
import Input from "../UI/Input";
import LazyLoading from "../UI/LazyLoading";
import Pagination from "../UI/Pagination";

export default function ToolkitPage() {
  let navigate = useNavigate();
  const [toolkits, setToolkits] = useState([]);
  const [toolz, setToolz] = useState([]);

  var adviewUrl = `${process.env.REACT_APP_BASEURL_API}/admin-view`;

  var imageUrl = `${process.env.REACT_APP_BASEURL_API}`;

  const getToolkits = async () => {
    const pageURL = adviewUrl;
    try {
      const response = await fetch(pageURL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // Always write this in order not to have CORS issues
        withCredentials: false,
        crossdomain: false,
        // mode: 'no-cors',
      });
      const jsonData = await response.json();
      setToolkits(jsonData);
      // console.log(toolkits);
    } catch (err) {
      console.error(err.message);
    }
  };

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let user = getCookie("userId");
    if (user !== "") {
      // console.log("User present");
    } else {
      // window.location="/login"
      navigate("/");
      // if (user !== "" && user != null) {
      //   setCookie("userId", user, 1);
      // }
    }
  }

  useEffect(() => {
    getToolkits();
    checkCookie();
  }, []);

  const searchToolz = (event) => {
    setToolz(event.target.value);
  };

  // toolkits is the array of data collected
  // dataSearch is helping to filter the toolkits

  let dataSearch = toolkits.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(toolz.toString().toLowerCase())
    );
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = dataSearch.slice(firstPostIndex, lastPostIndex);

  return (
    <>
      <div className="md:grid md:grid-cols-6">
        <div className="mb-6 md:mt-4 font-abel">
          <button
            className="bg-transparent border-2 border-gray-600 hover:text-gray-400  text-gray-600 w-full py-1 font-abel rounded-md"
            onClick={() => {
              navigate("/access-create");
            }}
          >
            <i className="fas fa-plus text-gray-600 pt-1 mr-2"></i>
            Create Toolkit
          </button>
        </div>
        <div className="col-end-7 col-span-2">
          {/* <input
            className="h-12 mt-4 w-1/2 mx-auto mb-4 text-md md:pl-12 pr-3 text-slate-600 font-abel placeholder:italic placeholder:text-sm md:placeholder:text-xl placeholder:text-slate-400 block bg-white border border-slate-300 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="Search for toolkit..."
            type="text"
            name="search"
            value={toolz}
            onChange={searchToolz.bind(this)}
          /> */}
          <Input
            placeholder="Search for toolkit..."
            type="text"
            value={toolz}
            onChange={searchToolz.bind(this)}
          />
        </div>
      </div>

      <div className={`${toolkits.length === 0 ? "hidden" : "block"}`}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pb-2">
          {currentPosts.map((toolkit, index) => (
            <div className="">
              {/* <Fade bottom duration={800} delay={500}> */}
              <div className="card overflow-y-hidden " key={toolkit.id}>
                <Link to={`/access-view/${toolkit.id}`}>
                  <img
                    className="preview-img"
                    src={`${imageUrl}/toolkits/${toolkit.toolkit_img}`}
                    alt="Toolkit_Image"
                  />
                </Link>
              </div>
              <Link to={`/access-view/${toolkit.id}`} className="text-link ">
                <h4 className="card-title overflow-y-hidden">
                  {toolkit.title}{" "}
                </h4>
              </Link>
            </div>
          ))}
        </div>
        <Pagination
          totalPosts={dataSearch.length}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>

      <div className={`${toolkits.length === 0 ? "block mt-4" : "hidden"}`}>
        <GridLazy />
        <br />
        <GridLazy />
        <br />
        <GridLazy />
      </div>
    </>
  );
}
