import React from "react";

export default function Button(props) {
  return (
    <div className={props.divName}>
      <button onClick={props.onClick} className={props.className}>
        {props.name}
      </button>
    </div>
  );
}
