import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import LazyLoading from "../UI/LazyLoading";

ChartJS.register(CategoryScale, LinearScale, BarElement);
const BarChart = () => {
  const [totalData, setTotalData] = useState(0);
  const [janData, setJanData] = useState(0);
  const [febData, setFebData] = useState(0);
  const [marData, setMarData] = useState(0);
  const [aprData, setAprData] = useState(0);
  const [mayData, setMayData] = useState(0);
  const [junData, setJunData] = useState(0);
  const [julData, setJulData] = useState(0);
  const [augData, setAugData] = useState(0);
  const [sepData, setSepData] = useState(0);
  const [octData, setOctData] = useState(0);
  const [novData, setNovData] = useState(0);
  const [decData, setDecData] = useState(0);

  var totalUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-total`;
  var janUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-jan`;
  var febUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-feb`;
  var marUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-mar`;
  var aprUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-apr`;
  var mayUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-may`;
  var junUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-jun`;
  var julUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-jul`;
  var augUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-aug`;
  var sepUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-sep`;
  var octUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-oct`;
  var novUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-nov`;
  var decUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-dec`;

  useEffect(() => {
    axios
      .get(totalUrl)
      .then((res) => {
        setTotalData(res.data.length);
        console.log(res.data.length);
        console.log(totalData);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(janUrl)
      .then((res) => {
        setJanData(res.data.length);
        // console.log(janData);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(febUrl)
      .then((res) => {
        setFebData(res.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(marUrl)
      .then((res) => {
        setMarData(res.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(aprUrl)
      .then((res) => {
        setAprData(res.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(mayUrl)
      .then((res) => {
        setMayData(res.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(junUrl)
      .then((res) => {
        setJunData(res.data.length);
        // console.log(junData);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(julUrl)
      .then((res) => {
        setJulData(res.data.length);
        // console.log(julData);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(augUrl)
      .then((res) => {
        setAugData(res.data.length);
        // console.log(augData);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(sepUrl)
      .then((res) => {
        setSepData(res.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(octUrl)
      .then((res) => {
        setOctData(res.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(novUrl)
      .then((res) => {
        setNovData(res.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(decUrl)
      .then((res) => {
        setDecData(res.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  var data = {
    labels: [
      "January",
      "Febuary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "Decemeber",
    ],
    datasets: [
      {
        label: "Total Downloads Per Month",
        data: [
          janData,
          febData,
          marData,
          aprData,
          mayData,
          junData,
          julData,
          augData,
          sepData,
          octData,
          novData,
          decData,
        ],
        // [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "#687EA8",
          "#E2C6C3",
          "#057E8C",
          "#F48076",
          "dodgerblue",
          "#ffbb33",
          "#7669ef",
          "wheat",
          "forestgreen",
          "#f2ea59",
          "#FF6363",
          "#00C851",
        ],
        borderColor: [
          "#687EA8",
          "#E2C6C3",
          "#057E8C",
          "#F48076",
          "dodgerblue",
          "#ffbb33",
          "#7669ef",
          "wheat",
          "forestgreen",
          "#f2ea59",
          "#FF6363",
          "#00C851",
        ],
        borderWidth: 1,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legends: {
      labels: {
        fontSize: 26,
      },
    },
  };

  return (
    <div>
      {totalData === 0 ? (
        <LazyLoading />
      ) : (
        <Bar data={data} options={options} height={400} />
      )}
    </div>
  );
};

export default BarChart;
