import React from "react";

export default function BigLoading() {
  return (
    <div className="border border-blue-300 shadow rounded-md p-4 max-w-full h-auto w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="rounded bg-slate-400 h-96 w-96 mb-8"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="space-y-4">
            <div className="grid grid-cols-4 gap-2">
              <div className="h-2 bg-slate-400 rounded col-span-2"></div>
              <div className="h-2 bg-slate-400 rounded col-span-2"></div>
            </div>
            <div className="h-2 bg-slate-400 rounded"></div>
            <div className="grid grid-cols-4 gap-2">
              <div className="h-2 bg-slate-400 rounded col-span-2"></div>
              <div className="h-2 bg-slate-400 rounded col-span-2"></div>
            </div>
          </div>
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="h-2 bg-slate-400 rounded"></div>
        </div>

        {/* <div className=" bg-slate-400 h-48 w-48 mb-8"></div> */}
      </div>
    </div>
  );
}
