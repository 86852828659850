import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import Pagination from "../UI/Pagination";
import LazyLoading from "../UI/LazyLoading";

export default function Events() {
  let navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [show, setShow] = useState(false);
  const [searchE, setSearchE] = useState([]);

  var eventViewUrl = `${process.env.REACT_APP_BASEURL_API}/events-view`;
  var deleteEventUrl = `${process.env.REACT_APP_BASEURL_API}/delete-event`;

  const getEvent = async () => {
    axios.get(eventViewUrl).then((res) => {
      const jsonData = res.data;
      setEvents(jsonData);
    });
  };

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let user = getCookie("userId");
    if (user !== "") {
      // console.log("User present");
    } else {
      // window.location="/login"
      navigate("/");
      // if (user !== "" && user != null) {
      //   setCookie("userId", user, 1);
      // }
    }
  }

  const deleteEvent = async (id) => {
    try {
      const deleteEvent = await fetch(`${deleteEventUrl}/${id}`, {
        method: "DELETE",
      });
      toast(`Event Deleted `, {
        type: "info",
      });
      setShow(false);
      setEvents(
        events.filter((event) => {
          return event.id !== id;
        })
      );

      // navigate("/events");
    } catch (err) {
      console.error(err.message);
    }
  };

  const searchEvent = (event) => {
    setSearchE(event.target.value);
  };

  // toolkits is the array of data collected
  // dataSearch is helping to filter the toolkits

  let dataSearch = events.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(searchE.toString().toLowerCase())
    );
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = dataSearch.slice(firstPostIndex, lastPostIndex);

  useEffect(() => {
    checkCookie();
    getEvent();
  }, []);

  return (
    <>
      <ToastContainer />

      <div className="text-3xl text-gray-800 text-center font-abel pb-16">
        Events
        <button
          className="border-2 border-green-500  text-lg hover:border-green-600 text-green-500 hover:text-green-600 float-right w-fit py-1 px-2 font-abel rounded-md"
          onClick={() => {
            navigate("/create-event");
          }}
        >
          <i className="fas fa-calendar-plus text-green-500  mr-2"></i>
          Create Event
        </button>
        <div className="md:-mt-8">
          <input
            type="text"
            className="bg-gray-50 border float-left font-abel border-gray-300 placeholder:font-normal placeholder:text-base text-gray-900 text-base  rounded-lg focus:ring-blue-500 focus:border-blue-500 w-32 md:w-48 p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search event..."
            required
            value={searchE}
            onChange={searchEvent.bind(this)}
          />
        </div>
      </div>
      <div className={`${events.length === 0 ? "hidden" : "block"}`}>
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-400 dark:bg-gray-600 dark:text-white">
              <tr>
                <th scope="col" className="py-3 px-6 dark:text-white">
                  Name
                </th>
                <th scope="col" className="py-3 px-6 dark:text-white">
                  Price
                </th>
                <th scope="col" className="py-3 px-6 dark:text-white">
                  Link
                </th>
                <th scope="col" className="py-3 px-6 dark:text-white">
                  Date
                </th>
                <th scope="col" className="py-3 px-6 dark:text-white">
                  Action
                </th>
              </tr>
            </thead>
            {currentPosts.map((event, index) => (
              <tbody>
                <tr className="bg-white border-b dark:bg-green-900 dark:border-gray-700 dark:text-white">
                  <th
                    scope="row"
                    className="py-4 px-6 capitalize font-bold text-gray-900 whitespace-nowrap "
                    key={index}
                  >
                    {event.eventname}
                  </th>
                  <td className="py-4 px-6 ">
                    {event.currency} {event.price}
                  </td>
                  <td className="py-4 px-6 ">
                    <button
                      className="text-blue-600 hover:text-green-600 hover:underline"
                      onClick={() => window.open(event.link)}
                    >
                      {event.link}
                    </button>{" "}
                  </td>
                  <td className="py-4 px-6 ">
                    {format(new Date(event.startDateTime), "dd MMMM yyyy")}
                  </td>
                  <td className="py-4 px-6 space-y-4">
                    <Link
                      to={`/update-event/${event.id}`}
                      title="Update Event"
                      className="font-medium mr-4  text-gray-500 ring-2 ring-gray-500  hover:ring-blue-500 py-1 px-2 rounded-md dark:text-gray-500"
                    >
                      {/* Edit */}
                      <i className="fas fa-clipboard-list text-gray-500 "></i>
                    </Link>
                    <button
                      // to={`/delete-event/${event.id}`}
                      onClick={() => setShow(true)}
                      title="Delete Event"
                      className="font-medium text-gray-500 ring-2 ring-gray-500 hover:ring-red-500 py-1 px-2 rounded-md dark:text-gray-500"
                    >
                      {/* Delete */}
                      <i className="fas fa-trash text-gray-500"></i>
                    </button>
                  </td>
                </tr>
                {show && (
                  <div
                    className="relative z-10"
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                  >
                    <div className="fixed inset-0 bg-gray-400 backdrop-blur-sm bg-opacity-20 transition-opacity"></div>

                    <div className="fixed z-10 inset-0 md:overflow-y-auto">
                      <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <div className="relative bg-white -top-64 md:top-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3
                                  className="text-lg md:text-2xl text-center leading-6 font-medium text-gray-900"
                                  id="modal-title"
                                >
                                  Do you want to Delete {event.eventname} ?
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="bg-gray-50 px-4  py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={(e) => deleteEvent(event.id)}
                            >
                              Delete
                            </button>
                            <br className="md:hidden" />
                            <br className="md:hidden" />
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-300 text-base font-medium text-black hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={() => setShow(false)}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </tbody>
            ))}
          </table>

          <Pagination
            totalPosts={dataSearch.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </div>

      <div className={`${events.length === 0 ? "block mt-8" : "hidden"}`}>
        <LazyLoading />
        <br />
        <LazyLoading />
      </div>
    </>
  );
}
