import "./App.css";
// import DashBoard from "./admin/DashBoard";
// import AdminView from "./admin/AdminView";
// import CreateToolkit from "./admin/CreateToolkit";
// import EditToolkit from "./admin/EditToolkit";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import PageError from "./access/PageError";
import LoginPage from "./access/LoginPage";
import ToolkitPage from "./access/ToolkitPage";
import Layout from "./access/Layout";
import Create from "./access/Create";
import ViewToolkit from "./access/ViewToolkit";
import ToolkitReport from "./access/ToolkitReport";
import CreateEvent from "./access/CreateEvent";
import Events from "./access/Events";
import AdminDashBoard from "./access/AdminDashBoard";
import UpdateEvent from "./access/UpdateEvent";

function App() {
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   return () => {
  //     setTimeout(()=> {
  //       setLoading(false)
  //     },1000) // set time for loader to 2 secs
  //   };
  // }, [])

  return (
    <Router>
      <div className="App">
        {/* {!loading &&  <Navbar/>  } */}
        <div className="content">
          {/* {loading ? (<PageLoader />) :  */}
          <Routes>
            <Route path="/" element={<LoginPage />} />
            {/* <Route path="/admin" element={<DashBoard />} />
            <Route path="/admin-view/:id" element={<AdminView />} />
            <Route path="/admin-create" element={<CreateToolkit />} />
            <Route path="/admin-edit/:id" element={<EditToolkit />} /> */}

            <Route
              path="/access"
              element={
                <Layout>
                  <AdminDashBoard />
                </Layout>
              }
            />
            <Route
              path="/toolkits"
              element={
                <Layout>
                  <ToolkitPage />
                </Layout>
              }
            />
            <Route
              path="/access-create"
              element={
                <Layout>
                  <Create />
                </Layout>
              }
            />

            <Route
              path="/access-view/:id"
              element={
                <Layout>
                  <ViewToolkit />
                </Layout>
              }
            />

            <Route
              path="/report"
              element={
                <Layout>
                  <ToolkitReport />
                </Layout>
              }
            />

            <Route
              path="/events"
              element={
                <Layout>
                  <Events />
                </Layout>
              }
            />
            <Route
              path="/create-event"
              element={
                <Layout>
                  <CreateEvent />
                </Layout>
              }
            />
            <Route
              path="/update-event/:id"
              element={
                <Layout>
                  <UpdateEvent />
                </Layout>
              }
            />

            <Route path="*" element={<PageError />} />
          </Routes>
          {/* }     */}
        </div>
        {/* {!loading &&  <Footer /> } */}
      </div>
    </Router>
  );
}

export default App;
