import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../UI/Button";
import Input from "../UI/Input";
import { CSVLink } from "react-csv";

export default function ToolkitReport() {
  let navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [diffData, setDiffData] = useState("");
  const [allData, setAllData] = useState("");
  const [errMsg, setErrMsg] = useState("");

  var diffDateUrl = `${process.env.REACT_APP_BASEURL_API}/report-diffDate`;
  var reportAllUrl = `${process.env.REACT_APP_BASEURL_API}/report-all`;

  const getDiffDate = async () => {
    setErrMsg("");

    const formdata = new FormData();

    formdata.append("startDate", startDate);
    formdata.append("endDate", endDate);

    if (startDate !== "" && endDate !== "") {
      try {
        await axios
          .post(diffDateUrl, formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "multipart/form-data",
            },
            withCredentials: false,
            crossdomain: false,
            mode: "no-cors",
          })
          .then((res) => {
            // console.log(res.json());
            const jsonDataWithDiff = res.data;
            setDiffData(jsonDataWithDiff);
          });
      } catch (error) {
        console.error(error.message);
      }
    } else {
      setErrMsg("Please input date range !!!");
    }
  };

  const reportAll = async () => {
    try {
      const response = await fetch(reportAllUrl, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // Always write this in order not to have CORS issues
        withCredentials: false,
        crossdomain: false,
        // mode: 'no-cors',
      });
      const jsonDataList = await response.json();

      setAllData(jsonDataList);
      // console.log(jsonDataList);
    } catch (err) {
      console.error(err.message);
    }
  };

  let today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate() +
      "__" +
      today.getHours() +
      "-" +
      today.getMinutes() +
      "-" +
      today.getSeconds();

  const headers = [
    { label: "name", key: "name" },
    { label: "email", key: "email" },
    { label: "toolkit_title", key: "toolkit_title" },
    { label: "toolkit_price", key: "toolkit_price" },
    { label: "date", key: "createdAt" },
  ];

  const toolkitsReport = {
    filename: `Toolkits_Total_Report_${date}.csv`,
    headers: headers,
    data: allData,
  };

  const headerdiffDate = [
    { label: "name", key: "name" },
    { label: "email", key: "email" },
    { label: "toolkit_title", key: "toolkit_title" },
    { label: "toolkit_price", key: "toolkit_price" },
    { label: "date", key: "createdAt" },
  ];

  const toolkitDiffReport = {
    filename: `Toolkits_Weekly_Report_${date}.csv`,
    headers: headerdiffDate,
    data: diffData,
  };

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let user = getCookie("userId");
    if (user !== "") {
      // console.log("User present");
    } else {
      // window.location="/login"
      navigate("/");
      // if (user !== "" && user != null) {
      //   setCookie("userId", user, 1);
      // }
    }
  }

  useEffect(() => {
    checkCookie();
    reportAll();
  }, []);

  return (
    <>
      <div className="mtext-3xl text-gray-800 text-center font-abel pb-16">
        Toolkit Reports
      </div>

      <div className="text-lg md:text-2xl text-gray-800 text-center font-aldrich pb-4">
        Download Toolkit Report Between Date Range
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 lg:px-12 md:px-4">
        <Input
          name="Start Date"
          placeholder="24th July, 2022"
          type="date"
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
        />
        <Input
          name="End Date"
          placeholder="28th August, 2022"
          type="date"
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
        />
      </div>
      <div
        className={` ${
          errMsg === ""
            ? "hidden"
            : "block text-center duration-75 font-abel text-md text-mhred"
        }`}
      >
        {errMsg}
      </div>

      <div
        className={` ${
          diffData === ""
            ? "hidden"
            : "block text-center duration-75 font-abel text-md text-mhblue"
        }`}
      >
        Report generated, you can download report now
      </div>
      <div className={`mx-auto w-fit md:w-2/6`}>
        <div className={`${diffData === "" ? "block" : "hidden"}`}>
          <Button
            divName="mb-6 mt-10"
            onClick={() => getDiffDate()}
            name="Generate Report"
            className="border-2 border-green-700 hover:bg-green-700 float-right hover:text-white text-gray-700 p-3 w-full md:py-3 font-abel rounded-md"
          />
        </div>
        <div className={`mb-6 mt-10 ${diffData === "" ? "hidden" : "block"}`}>
          <CSVLink
            {...toolkitDiffReport}
            className="border-2 border-gray-700 text-center hover:bg-gray-700 float-right hover:text-white text-gray-700 p-3 w-full py-3 font-abel rounded-md"
          >
            Download Report
          </CSVLink>
        </div>
      </div>

      <div className="text-lg md:text-2xl text-gray-800 text-center font-aldrich pt-16 pb-8">
        Download Overall Toolkits Report
      </div>
      <div className="w-fit md:w-2/6 mx-auto">
        <CSVLink
          {...toolkitsReport}
          className="border-2 border-blue-700 text-center hover:bg-blue-700 float-right hover:text-white text-gray-700 w-full p-3 py-3 font-abel rounded-md"
        >
          Download Report
        </CSVLink>
      </div>
    </>
  );
}
