import React, { useEffect, useState } from "react";
import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import LazyLoading from "../UI/LazyLoading";

ChartJS.register(Tooltip, Legend, ArcElement);
const DoughnutChart = () => {
  const [chartData, setChartData] = useState([]);
  // const [checkStatus, setCheckStatus] = useState(0);

  // useEffect(() => {
  //   if (chartData.length === 0) {
  //     setCheckStatus(1);
  //     // console.log("we never come");
  //   }
  //   if (chartData.length > 1) {
  //     setCheckStatus(2);
  //     // console.log("we don arrive");
  //   }
  // }, [chartData]);

  var toolkitUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-view`;

  useEffect(() => {
    axios
      .get(toolkitUrl)
      .then((res) => {
        const jsonData = res.data;
        setChartData(jsonData);
        // console.log(jsonData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  var data = {
    labels: chartData.map((x) => x.title), // ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "Most Downloaded Toolkits",
        data: chartData.map((x) => x.count), // [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "#687EA8",
          "#E2C6C3",
          "#057E8C",
          "#F48076",
          "dodgerblue",
          "#ffbb33",
          "#7669ef",
          "wheat",
          "forestgreen",
          "#f2ea59",
          "#FF6363",
          "#00C851",
        ],
        borderColor: [
          "#687EA8",
          "#E2C6C3",
          "#057E8C",
          "#F48076",
          "dodgerblue",
          "#ffbb33",
          "#7669ef",
          "wheat",
          "forestgreen",
          "#f2ea59",
          "#FF6363",
          "#00C851",
        ],
        borderWidth: 1,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    legends: {
      labels: {
        fontSize: 26,
      },
    },
  };

  return (
    <div>
      {chartData.length === 0 ? (
        <>
          <LazyLoading />
        </>
      ) : (
        <Doughnut data={data} options={options} height={400} />
      )}
    </div>
  );
};

export default DoughnutChart;
