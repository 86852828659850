import React from "react";

export default function Select(props) {
  return (
    <div className="mb-6 font-abel">
      <label className="block mb-2 text-md font-normal text-gray-900 dark:text-gray-300">
        {props.name}
      </label>
      <select
        className="form-select bg-gray-50 border border-gray-300 placeholder:font-normal text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        aria-label="Default select example"
        required
        onChange={props.onChange}
      >
        <option selected>--Select {props.name}--</option>
        <option value={props.value1}>{props.value1}</option>
        <option value={props.value2}>{props.value2}</option>
        <option value={props.value3}>{props.value3}</option>
      </select>
    </div>
  );
}
