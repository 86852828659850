import axios from "axios";
import React from "react";
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  let navigate = useNavigate();
  const emailRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  // axios.defaults.withCredentials = true;

  // useEffect(() => {
  //   return () => {
  //     emailRef.current.focus();
  //   };
  // }, [])

  useEffect(() => {
    return () => {
      setErrMsg("");
      checkCookie();
    };
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      }

      const response = await axios
        .post(
          `${process.env.REACT_APP_LOGIN_API}`,
          {
            email: email,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            // Always write this in order not to have CORS issues
            withCredentials: false,
            crossdomain: false,
          }
        )
        .then((response) => {
          // console.log(response);
          // set cookie here
          setCookie("userId", email, 1); // 1 days till timeout
          // window.location="/"
          navigate("/access");
        });

      setEmail("");
      setPassword("");
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Response From Server");
      } else if (error.response?.status === 401) {
        setErrMsg("Unauthorized Admin");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
      console.log(error);
    }
  };

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let user = getCookie("userId");
    if (user !== "") {
      // window.location="/"
      navigate("/access");
    } else {
      navigate("/");
      // if (user !== "" && user != null) {
      //   setCookie("userId", user, 1); // 1 days till timeout
      //   //  window.location="/login"
      //   navigate("/");
      // }
    }
  }

  return (
    <>
      {/* {success ? 
        (navigate('../'))
    :( */}

      <div className="maincontainer">
        <div className="container-fluid">
          <div className="row no-gutter">
            <div className="col-md-6 d-none d-md-flex bg-image"></div>

            <div className="col-md-6 bg-light">
              <div className="login d-flex align-items-center py-5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10 col-xl-7 mx-auto">
                      {/* Error Message */}
                      <p
                        className={errMsg ? "errMsg" : "offscreen"}
                        ref={errRef}
                        aria-live="assertive"
                      >
                        {errMsg}
                      </p>
                      {/* Error Ends */}
                      <h1 className="display-6 text-center mh-name">
                        MH Toolkits
                      </h1>
                      <p className="text-muted text-center mb-4">
                        Sign in to create a toolkit
                      </p>
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <input
                            id="email"
                            type="email"
                            placeholder="Email address"
                            required
                            autoComplete="off"
                            className="bg-gray-50 border border-gray-300 placeholder:font-normal text-gray-900 text-md rounded-full focus:ring-mhcolor focus:border-mhcolor block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            ref={emailRef}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            id="password"
                            type="password"
                            placeholder="Password"
                            required
                            className="bg-gray-50 border border-gray-300 placeholder:font-normal text-gray-900 text-md rounded-full focus:ring-mhcolor focus:border-mhcolor block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                        </div>
                        <div className="d-grid gap-2 mt-2">
                          <button
                            type="submit"
                            // className="btn btn-block text-uppercase mb-2 rounded-pill shadow-sm mh-tk-btn"
                            className="bg-mhblack hover:bg-mhcolor float-right text-mhcolor hover:text-mhblack w-full py-[13px] font-abel rounded-full"
                          >
                            Sign in
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default LoginPage;
