import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function Layout({ children }) {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(true);
  const location = useLocation();
  // console.log(location.pathname);

  const [active, setActive] = useState(location.pathname);

  const Menus = [
    {
      title: "Dashboard",
      src: "fas fa-home",
      link: "/access",
      active: "/access",
    },
    {
      title: "Toolkits",
      src: "fas fa-book",
      link: "/toolkits",
      active: "/toolkits",
    },

    {
      title: "Toolkit Report",
      src: "fas fa-chart-bar",
      link: "/report",
      active: "/report",
    },
    {
      title: "Events",
      src: "fas fa-calendar-alt",
      link: "/events",
      active: "/events",
    },
  ];

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function checkout() {
    setCookie("userId", "", -2);
    navigate("/");
  }
  return (
    <>
      <div className="flex grid-cols-2">
        <div
          className={`${
            open ? "lg:w-48 w-14 md:w-14" : "w-14 md:w-40 lg:w-14"
          } shadow-2xl drop-shadow-2xl duration-500 h-auto pt-2 md:col-span-3 bg-white relative`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setOpen(!open)}
            className={`hidden md:block absolute cursor-pointer border-2 rounded-full bg-white border-red-500 text-mhred -right-3 top-12 w-8 h-8 ${
              !open && "rotate-180 pl-2"
            }`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          <div className="flex gap-x-4 items-center">
            <img
              src="mh.png"
              className={`cursor-pointer duration-300 ml-1 w-12 md:w-16 h-10 bg-white py-1 rounded-md  ${
                !open ? "md:w-12" : ""
              }`}
              alt=""
            />
            <h1
              className={`text-gray-500 origin-left font-abel font-medium text-2xl duration-300 ${
                !open ? "hidden md:block lg:hidden" : "hidden  lg:block"
              } `}
            >
              Admin
            </h1>
          </div>

          <ul className="mt-8">
            {Menus.map((menu, index) => (
              <Link to={menu.link}>
                <li
                  key={index}
                  className={`${
                    active === menu.active
                      ? "bg-white text-gray-700"
                      : "text-gray-400"
                  } text-lg flex items-center gap-x-4
                            cursor-pointer p-1   hover:bg-white hover:text-blue-800 mt-2 `}
                  onClick={() => {
                    setActive(menu.active);
                  }}
                >
                  <i
                    className={`${menu.src}  cursor-pointer duration-300 text-inherit ml-2 mt-6 items-center text-center w-7 h-7`}
                  ></i>

                  <span
                    className={`${
                      !open ? "hidden lg:hidden" : "md:hidden lg:block"
                    } duration-300 hidden md:block origin-left text-inherit font-montserrat mt-3`}
                  >
                    {menu.title}
                  </span>
                </li>
              </Link>
            ))}
            <button
              onClick={(e) => setShow(true)}
              className=" text-lg flex items-center gap-x-4 mt-4 "
            >
              <div className="items-center inline-flex gap-x-4 text-[#9ca3af] text-inherit font-montserrat">
                <i className="fas fa-sign-out-alt cursor-pointer duration-300 text-inherit ml-3 mt-2 items-center text-center w-7 h-7"></i>
                <p
                  className={`${
                    !open
                      ? "hidden md:block lg:hidden text-[#9ca3af]"
                      : " md:hidden lg:inline-block duration-300 text-[#9ca3af]"
                  }`}
                >
                  Logout
                </p>{" "}
              </div>
            </button>
          </ul>
        </div>

        <div className="p-7 text-2xl font-semibold flex-1 h-screen ">
          {children}

          {show && (
            <div
              className="relative z-10"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div className="fixed inset-0 bg-gray-400 backdrop-blur-sm bg-opacity-75 transition-opacity"></div>

              <div className="fixed z-10 inset-0 md:overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                  <div className="relative bg-white -top-64 md:top-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3
                            className="text-lg md:text-2xl text-center leading-6 font-medium text-gray-900"
                            id="modal-title"
                          >
                            Are you sure you want to Logout ?
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4  py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={(e) => checkout()}
                      >
                        Logout
                      </button>
                      <br className="md:hidden" />
                      <br className="md:hidden" />
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-300 text-base font-medium text-black hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setShow(false)}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
