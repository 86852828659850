import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BarChart from "../chart/BarChart";
import DoughnutChart from "../chart/DoughnutChart";
import LineChart from "../chart/LineChart";
import PieChart from "../chart/PieChart";
import LazyLoading from "../UI/LazyLoading";

export default function AdminDashBoard() {
  let navigate = useNavigate();
  let today = new Date();
  // console.log(today);

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let user = getCookie("userId");
    if (user !== "") {
      // console.log("User present");
    } else {
      // window.location="/login"
      navigate("/");
      // if (user !== "" && user != null) {
      //   setCookie("userId", user, 1);
      // }
    }
  }

  useEffect(() => {
    checkCookie();
  }, []);
  return (
    <>
      <div className="text-3xl text-gray-800 text-center font-abel pb-16">
        Admin DashBoard
      </div>
      <div className="text-2xl text-gray-800 text-center font-aldrich pb-8">
        Total Downloads Per Month {today.getFullYear()}
      </div>
      {/* <LazyLoading /> */}
      <BarChart />
      <div className="text-2xl mt-12 text-gray-800 text-center font-aldrich pb-8">
        Most Downloaded Toolkit
      </div>
      <DoughnutChart />

      {/* Other Charts */}
      {/* <LineChart /> */}
      {/* <PieChart /> */}
    </>
  );
}
