import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../UI/Input";
import TextArea from "../UI/TextArea";
import Button from "../UI/Button";
import Select from "../UI/Select";

export default function UpdateToolkit() {
  let navigate = useNavigate();
  const params = useParams();
  const [toolkit, setToolkit] = useState([]);
  const [errMsg, setErrMsg] = useState("");

  var adeditUrl = `${process.env.REACT_APP_BASEURL_API}/admin-edit/${params.id}`;

  var viewUrl = `${process.env.REACT_APP_BASEURL_API}/admin-edit/${params.id}`;

  var imageUrl = `${process.env.REACT_APP_BASEURL_API}`;

  const getToolkit = async () => {
    try {
      const response = await fetch(viewUrl, {
        method: "GET",
      });
      const jsonData = await response.json();
      setToolkit(jsonData);
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [important_info, setImportantInfo] = useState("");
  const [price, setPrice] = useState("");
  const [categ, setCateg] = useState("");
  const [toolkit_file, setToolKitFile] = useState("");

  const [toolkit_img, setToolkitImg] = useState({
    file: [],
    filepreview: null,
  });

  //  const [toolkit_file, setToolKitFile] = useState({
  //   file:[],
  //   filepreview:null,
  //  });

  const handleImage = (event) => {
    setToolkitImg({
      ...toolkit_img,
      file: event.target.files[0],
      filepreview: URL.createObjectURL(event.target.files[0]),
    });
  };

  // const handleFile = (event) => {
  //   setToolKitFile({
  //     ...toolkit_file,
  //     file:event.target.files[0],
  //     filepreview:URL.createObjectURL(event.target.files[0]),
  //   });

  // }

  const updateToolKit = async () => {
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("description", description);
    formdata.append("important_info", important_info);
    formdata.append("price", price);
    formdata.append("categ", categ);
    formdata.append("toolkit_img", toolkit_img.file);
    formdata.append("toolkit_file", toolkit_file);

    if (
      title === "" ||
      price === "" ||
      categ === "" ||
      description === "" ||
      important_info === "" ||
      toolkit_file === ""
    ) {
      setErrMsg("Missing input field");
    } else {
      setErrMsg("");

      axios
        .put(adeditUrl, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
          // Always write this in order not to have CORS issues
          withCredentials: false,
          crossdomain: false,
          mode: "no-cors",
        })
        .then((res) => {
          toast(`Toolkit Updated Successfully `, {
            type: "success",
          });
          setTimeout(() => {
            navigate(`/access`);
          }, 6000);
        });
    }
  };

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let user = getCookie("userId");
    if (user !== "") {
      // console.log("User present");
    } else {
      // window.location="/login"
      navigate("/");
      // if (user !== "" && user != null) {
      //   setCookie("userId", user, 1);
      // }
    }
  }

  useEffect(() => {
    checkCookie();
    getToolkit();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="text-3xl text-gray-800 text-center font-aldrich pb-16">
        Update Toolkit
      </div>

      <div
        className={` ${
          errMsg === ""
            ? "hidden"
            : "block text-center duration-75 font-abel text-md text-mhred"
        }`}
      >
        {errMsg}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 lg:px-12 md:px-4">
        <Input
          name="Title"
          type="text"
          placeholder="Toolkit Title"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />

        <Input
          name="Price"
          type="number"
          placeholder="Toolkit Price"
          onChange={(e) => {
            setPrice(e.target.value);
          }}
        />

        <Select
          name="Category"
          value1="childcare"
          value2="household"
          value3="work"
          onChange={(e) => {
            setCateg(e.target.value);
          }}
        />

        <Input
          name="Url"
          type="text"
          placeholder="https://motherhonestly.com"
          onChange={(e) => {
            setToolKitFile(e.target.value);
          }}
        />

        <TextArea
          name="Description"
          rows={5}
          placeholder="Toolkit Description"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />

        <TextArea
          name="Important Information"
          rows={5}
          placeholder="Toolkit Important Information"
          onChange={(e) => {
            setImportantInfo(e.target.value);
          }}
        />

        <Input
          name="Image"
          type="file"
          placeholder="Toolkit Image"
          onChange={handleImage}
        />

        <Button
          divName="mb-6 mt-10"
          onClick={() => updateToolKit()}
          name="Update"
          className="bg-yellow-400 hover:bg-yellow-300 float-right text-black w-full py-[13px] font-abel rounded-md"
        />
      </div>
    </>
  );
}
