import React from "react";

export default function GridLazy() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {/* Grid 1 Starts */}
      <div className="border border-blue-300 shadow-sm rounded-md p-4 max-w-full h-48 w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded bg-slate-300 h-28 w-28"></div>
          <div className="flex-1 space-y-2 py-1">
            <div className="h-2 bg-slate-300 rounded"></div>
            <div className="h-2 bg-slate-300 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-4 gap-2">
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
              </div>
              <div className="grid grid-cols-4 gap-2">
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
              </div>
              <div className="h-2 bg-slate-300 rounded"></div>
              <div className="h-2 bg-slate-300 rounded"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Grid 1 Ends */}

      {/* Grid 2 Starts */}
      <div className="border border-blue-300 shadow rounded-md p-4 max-w-full h-48 w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded bg-slate-300 h-28 w-28"></div>
          <div className="flex-1 space-y-2 py-1">
            <div className="h-2 bg-slate-300 rounded"></div>
            <div className="h-2 bg-slate-300 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-4 gap-2">
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
              </div>
              <div className="grid grid-cols-4 gap-2">
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
              </div>
              <div className="h-2 bg-slate-300 rounded"></div>
              <div className="h-2 bg-slate-300 rounded"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Grid 2 Ends */}

      {/* Grid 3 Starts */}
      <div className="border border-blue-300 shadow rounded-md p-4 max-w-full h-48 w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded bg-slate-300 h-28 w-28"></div>
          <div className="flex-1 space-y-2 py-1">
            <div className="h-2 bg-slate-300 rounded"></div>
            <div className="h-2 bg-slate-300 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-4 gap-2">
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
              </div>
              <div className="grid grid-cols-4 gap-2">
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
              </div>
              <div className="h-2 bg-slate-300 rounded"></div>
              <div className="h-2 bg-slate-300 rounded"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Grid 3 Ends */}

      {/* Grid 4 Starts */}
      <div className="border border-blue-300 shadow rounded-md p-4 max-w-full h-48 w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded bg-slate-300 h-28 w-28"></div>
          <div className="flex-1 space-y-2 py-1">
            <div className="h-2 bg-slate-300 rounded"></div>
            <div className="h-2 bg-slate-300 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-4 gap-2">
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
              </div>
              <div className="grid grid-cols-4 gap-2">
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                <div className="h-2 bg-slate-300 rounded col-span-2"></div>
              </div>
              <div className="h-2 bg-slate-300 rounded"></div>
              <div className="h-2 bg-slate-300 rounded"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Grid 4 Ends */}
    </div>
  );
}
