import { Link } from "react-router-dom";

const PageError = () => {
  return (
    <div className="page-error ">
      <img
        className="mt-5 mb-3 mx-auto"
        src="../mh404.gif"
        alt="PageError"
        width="600"
        height="500"
      />

      <h2 className="text-3xl">Seems You Are Lost</h2>
      <Link to={"/"} className="text-mhred">
        Go Back to Homepage
      </Link>
    </div>
  );
};

export default PageError;
